import React from "react";
import LogoImage from "../assets/app_logo.jpg";

const Privacy: React.FC = () => {
  return (
    <>
      <div className="ac">
        <h1 className="mt20">Privacy Policy</h1>
        <img className="appLogoPrivacy mt40" src={LogoImage} alt="Logo" />
      </div>

      <div className="mt30 container">
        <p>
          Thank you for choosing SPF ("the App," "we," "us," or "our"). This
          Privacy Policy is intended to inform you about the types of
          information we collect, how we use it, and your choices regarding your
          information. Please read this Privacy Policy carefully before using
          the App.
        </p>
        <h2>Information We Collect</h2>
        <ul>
          <li>
            <b>Personal Information:</b> We may collect personal information
            such as your name, email address, and phone number when you create
            an account or update your profile.
          </li>
          <li>
            <b>User-Generated Content:</b> We may collect content that you
            create, upload, or share on the App, including posts, comments,
            messages, and profile image.
          </li>
          <li>
            <b>Image Collection:</b> We may ask you to upload a profile image
            during the creation or updating of your profile. This image will be
            stored and associated with your account for identification purposes.
          </li>
          <li>
            <b>Usage Data:</b> We may automatically collect certain information
            about your device and how you interact with the App, including your
            IP address, device type, operating system, browser type, pages
            visited, and app usage patterns.
          </li>
        </ul>
        <h2>How We Use Your Information</h2>
        <ul>
          <li>
            <b>To Provide and Improve the App:</b> We use your information to
            operate, maintain, and enhance the functionality of the App,
            including providing personalized features and content.
          </li>
          <li>
            <b>Communication:</b> We may use your contact information to send
            you administrative messages, updates, and notifications related to
            the App.
          </li>
        </ul>
        <h2>Information Sharing and Disclosure</h2>
        <ul>
          <li>
            <b>Service Providers:</b> We may share your information with
            third-party service providers who assist us in operating the App,
            such as hosting providers, analytics services, and customer support
            providers.
          </li>
          <li>
            <b>Legal Compliance:</b> We may disclose your information if
            required by law or in response to valid legal requests, such as
            court orders or subpoenas.
          </li>
          <li>
            <b>Consent:</b> We may share your information with third parties
            with your consent or at your direction.
          </li>
        </ul>
        <h2>Data Security</h2>
        <p>
          We take reasonable measures to protect the security of your
          information and prevent unauthorized access, disclosure, or
          alteration. However, no method of transmission over the internet or
          electronic storage is 100% secure, so we cannot guarantee absolute
          security. In future, if you want to delete an account,{" "}
          <a
            href="https://www.sirpratapfoundation.com/delete-account"
            target="_blank"
            rel="noreferrer"
          >
            Click Here
          </a>
        </p>
        <h2>Your Choices</h2>
        <p>
          You may update, correct, or delete your account information at any
          time by accessing your account settings within the App. You may also
          opt-out of receiving promotional emails from us by following the
          instructions provided in the emails.
        </p>
        <h2>Children's Privacy</h2>
        <p>
          The App is not intended for children under the age of 13, and we do
          not knowingly collect personal information from children under 13. If
          you believe that we may have collected information from a child under
          13, please contact us immediately.
        </p>
        <h2>Changes to this Privacy Policy</h2>
        <p>
          We reserve the right to update or modify this Privacy Policy at any
          time, and such changes will be effective upon posting the revised
          Privacy Policy on the App. Your continued use of the App after any
          such changes indicates your acceptance of the revised Privacy Policy.
        </p>
        <h2>Contact Us</h2>
        <p>
          If you have any questions or concerns about this Privacy Policy,
          please contact us at{" "}
          <a href="mailto:info@sirpratapfoundation.com">
            info@sirpratapfoundation.com
          </a>
          .
        </p>
      </div>
    </>
  );
};

export default Privacy;
