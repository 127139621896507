import React from "react";
import { Button, Col, Modal, ModalBody, ModalHeader, Row } from "reactstrap";

interface IProps {
  title: string;
  description: string;
  onConfirm: () => void;
  onClose: (isRefresh?: boolean) => void;
}
const ConfirmationPopup = (props: IProps) => {
  const { title, description, onClose, onConfirm } = props;

  return (
    <div>
      <Modal isOpen={true} backdrop="static" className="addEditPopup">
        <ModalHeader>{title}</ModalHeader>
        <ModalBody>
          <div>{description}</div>
          <Row className="ar mt10">
            <Col>
              <Button
                color="success"
                type="button"
                className="ml10 submitBtn"
                onClick={() => onConfirm()}
              >
                Yes
              </Button>
              <Button
                color="secondary"
                className="ml10"
                type="button"
                onClick={() => onClose(false)}
              >
                No
              </Button>
            </Col>
          </Row>
        </ModalBody>
      </Modal>
    </div>
  );
};

export default ConfirmationPopup;
