import React from "react";
import logo from "../logo.svg";

const Home = () => {
  return (
    <div>
      <header className="App-header">
        <img src={logo} className="App-logo" alt="logo" />
        <p>SirPratapFoundation ... Coming Soon</p>
      </header>
    </div>
  );
};

export default Home;
