import { ErrorNotify } from "../components/Notifications";
const API_COMMON_HEADERS = {
  "Content-Type": "application/json",
};

const API_BASE_URL = process.env.REACT_APP_API_URL;
export default class APIService {
  static doPostCall(routeUrl: string, data: any) {
    const uri = API_BASE_URL + "" + routeUrl;
    return fetch(uri, {
      headers: { ...API_COMMON_HEADERS },
      method: "POST",
      body: JSON.stringify(data),
    })
      .then((res) => res.json())
      .catch((resp) => {
        ErrorNotify("Internal server error, Please try again!");
      });
  }

  static doPutCall(routeUrl: string, data: any) {
    const uri = API_BASE_URL + "" + routeUrl;
    return fetch(uri, {
      headers: { ...API_COMMON_HEADERS },
      method: "PUT",
      body: JSON.stringify(data),
    })
      .then((res) => res.json())
      .catch((resp) => {
        ErrorNotify("Internal server error, Please try again!");
      });
  }

  static doGetCall(queryURL: string) {
    const uri = API_BASE_URL + "" + queryURL;
    return fetch(uri, {
      headers: { ...API_COMMON_HEADERS },
      method: "GET",
    })
      .then((res) => res.json())
      .catch((resp) => {
        ErrorNotify("Internal server error, Please try again!");
      });
  }

  static doDeleteCall(queryURL: string) {
    const uri = API_BASE_URL + "" + queryURL;
    return fetch(uri, {
      headers: { ...API_COMMON_HEADERS },
      method: "DELETE",
    })
      .then((res) => res.json())
      .catch((resp) => {
        ErrorNotify("Internal server error, Please try again!");
      });
  }
}
