import { Store } from "react-notifications-component";

export const SuccessNotify = (message: string) => {
  Store.addNotification({
    title: "Success!",
    message: message,
    type: "success",
    insert: "bottom",
    container: "bottom-right",
    animationIn: ["animate__animated", "animate__fadeInRightBig"],
    animationOut: ["animate__animated", "animate__fadeOutRightBig"],
    dismiss: {
      duration: 3000,
    },
  });
};

export const ErrorNotify = (message: string) => {
  Store.addNotification({
    title: "Error!",
    message: message,
    type: "danger",
    insert: "bottom",
    container: "bottom-right",
    animationIn: ["animate__animated", "animate__fadeInRightBig"],
    animationOut: ["animate__animated", "animate__fadeOutRightBig"],
    dismiss: {
      duration: 8000,
    },
  });
};

export const InfoNotify = (message: string) => {
  Store.addNotification({
    title: "Info!",
    message: message,
    type: "info",
    insert: "bottom",
    container: "bottom-right",
    animationIn: ["animate__animated", "animate__fadeIn"],
    animationOut: ["animate__animated", "animate__fadeOut"],
    dismiss: {
      duration: 5000,
    },
  });
};
