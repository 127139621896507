import { Routes, Route, Navigate } from "react-router-dom";
import Privacy from "../pages/Privacy";
import DeleteAccount from "../pages/DeleteAccount";
import Home from "../pages/Home";

const AppRoutes = () => {
  return (
    <Routes>
      <Route path="/" element={<Navigate replace to="/home" />} />
      <Route path="/privacy" element={<Privacy />} />
      <Route path="/delete-account" element={<DeleteAccount />} />
      <Route path="/home" element={<Home />} />
    </Routes>
  );
};

export default AppRoutes;
