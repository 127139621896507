import React from "react";
import "./App.css";
import AppRoutes from "./routes/AppRoutes";

function App() {
  return (
    <div>
      {/* <header className="App-header">
        <img src={logo} className="App-logo" alt="logo" />
        <p>SirPratapFoundation ... Coming Soon</p>
      </header> */}
      <AppRoutes />
    </div>
  );
}

export default App;
