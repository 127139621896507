import React from "react";
import GridLoader from "react-spinners/GridLoader";

function LoadingSpineer() {
  return (
    <div className="mySpineer">
      <GridLoader color="#2b88cb" />
    </div>
  );
}
export default LoadingSpineer;
