import React, { useRef, useState } from "react";
import LogoImage from "../assets/app_logo.jpg";
import { Formik, Field, Form, ErrorMessage } from "formik";
import { Button, Row, Col } from "reactstrap";
import * as Yup from "yup";
import validator from "validator";
import ConfirmationPopup from "../components/ConfirmationPopup";
import LoadingSpineer from "../components/LoadingSpineer";
import APIService from "../services/api";
import { ErrorNotify, SuccessNotify } from "../components/Notifications";

const DeleteAccount = () => {
  const [deleteConfirmation, setDeleteConfirmation] = useState<boolean>(false);
  const [otpSentFor, setOtpSentFor] = useState<string>("");
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const emailForm = useRef<any>(null);

  const validationSchema = Yup.object({
    emailAddress: Yup.string()
      .nullable()
      .test("validEmail", "Please enter valid email", (val) => {
        return validator.isEmail(val ?? "");
      }),
  });
  const otpValidationSchema = Yup.object({
    otp: Yup.string().required("Please enter OTP"),
  });

  const deleteAccount = async () => {};

  const handleSendOTP = async (values: any, formikProps: any) => {
    setIsLoading(true);
    const respEmail = await APIService.doPostCall("user/sendOtpToDelete", {
      email: values.emailAddress,
    });

    setIsLoading(false);
    if (respEmail.status) {
      emailForm.current = formikProps;
      setOtpSentFor(values.emailAddress);
      SuccessNotify("OTP sent over email addresss");
    } else {
      ErrorNotify("Not valid email address");
    }
  };

  const handleVerifyOTP = async (values: any, formikProps: any) => {
    setIsLoading(true);
    const respOtp = await APIService.doPostCall("user/deleteUser", {
      email: otpSentFor,
      otp: values.otp,
    });
    setIsLoading(false);

    if (respOtp.status) {
      setOtpSentFor("");
      emailForm.current.resetForm();
      formikProps.resetForm();
      SuccessNotify("Account delete successfully.");
    } else {
      ErrorNotify("OTP verification failed.");
    }
  };

  return (
    <div>
      {isLoading && <LoadingSpineer />}
      <div className="ac">
        <h1 className="pd20">Delete Account</h1>
        <img className="appLogoPrivacy mt40" src={LogoImage} alt="Logo" />
      </div>

      <div className="mt30 container">
        <p>
          Before you proceed to delete your account, please note that this
          action is irreversible. Once your account is deleted, all your
          information, comments, and messages, will be permanently removed from
          our system. If you're sure you want to proceed, please follow the
          steps below:
        </p>

        <Formik
          initialValues={{
            emailAddress: "",
          }}
          validationSchema={validationSchema}
          enableReinitialize={true}
          onSubmit={handleSendOTP}
        >
          {(formikProps) => (
            <Form>
              <div className="labelDelete">Email address here:</div>
              <Row className="mt10">
                <Col className="col-3">
                  <Field
                    className="emailF"
                    name="emailAddress"
                    placeholder="Email address"
                    type="email"
                  />
                  <ErrorMessage
                    className="errorBox"
                    name="emailAddress"
                    component="div"
                  />
                </Col>
                <Col className="">
                  <Button
                    color="primary"
                    type="submit"
                    className="submitBtn"
                    disabled={formikProps.values.emailAddress.length === 0}
                  >
                    Get OTP
                  </Button>
                </Col>
              </Row>
            </Form>
          )}
        </Formik>
        <div className="mt20">
          <Formik
            enableReinitialize={true}
            initialValues={{
              otp: "",
            }}
            validationSchema={otpValidationSchema}
            onSubmit={handleVerifyOTP}
          >
            {(formikProps) => (
              <Form>
                <div className="labelDelete">Enter OTP here:</div>
                {otpSentFor.length > 0 && (
                  <div className="otpSent">OTP sent for - {otpSentFor}</div>
                )}
                <Row className="mt10">
                  <Col className="col-3">
                    <Field
                      className="emailF"
                      disabled={otpSentFor.length === 0}
                      name="otp"
                      placeholder="OTP"
                      type="text"
                    />
                    <ErrorMessage
                      className="errorBox"
                      name="otp"
                      component="div"
                    />
                  </Col>
                  <Col className="">
                    <Button
                      color="success"
                      type="submit"
                      disabled={formikProps.values.otp.length < 1}
                      className="submitBtn"
                    >
                      Verify & Delete Account
                    </Button>
                  </Col>
                </Row>
              </Form>
            )}
          </Formik>
        </div>
        {deleteConfirmation && (
          <ConfirmationPopup
            title="Final Confirmation"
            description="Are you sure you want to delete your account?"
            onConfirm={deleteAccount}
            onClose={(value) => setDeleteConfirmation(value || false)}
          />
        )}
      </div>
    </div>
  );
};

export default DeleteAccount;
